import osintAxios  from "./axios/osint-axios"
import blackboxAxios from "./blackbox-axios"
import { default as rawAxios } from "axios";
import {getBaseUrlDelviumBackend} from "@shared/utils/functions"
import {default as ax} from "axios";

export const fetchOffshoreRecords = async (payload) => {
    let sourceBody = { "name": payload.name, "sources": ["offshoreleaks"], "age": [], "search_mode": "exact_match", "icij_entity_type": [], "limit": 100, "page": 1 }
    const url = "/api/v1/adverse-media/icij-search";
    const result = await getResultQueryID(sourceBody)
    let newPayload = { ...payload, request_id: result.data.request_id }
    return await osintAxios.post(url, newPayload)
}

export const fetchAdverseMedia = async (payload) => {
    const url = "/api/v1/adverse-media/search"
    const result = await getResultQueryID({...payload, "sources": ["complyadvantage"] })
    let newPayload = { ...payload, request_id: result.data.request_id }
    return await osintAxios.post(url, newPayload)
}

export async function getResultQueryID(payload) {
    const url = "/api/v1/adverse-media/search/sources";
    return await osintAxios.post(url, payload);
}

export async function getOffshoreResultDetail(payload) {
    const url = "/api/v1/adverse-media/icij-links";
    return await osintAxios.post(url, payload)
}

export async function saveResult(payload) {
    const url = "/api/v1/osint-tools/card/mark-as-saved";
    return await blackboxAxios.post(url, payload)
}



export async function fetchAllQueryResult(payload) {
    const url = '/api/v1/osint-tools/all/query/results'
    return await blackboxAxios.post(url, payload)
}

export async function fetchToolStatus(payload) {
    const url = "/api/v1/tools-status"
    return await blackboxAxios.post(url, {...payload})
}

export async function getSaasToken(client_id, token) {
    const url = "https://backend.delvium.xyz/api/v1/user/saas-token"
    return await ax.post(url, {client_id}, {headers:{"Authorization":`Bearer ${token}`}})
}

export async function getSaasUrl(payload,saasToken) {
    const url = "https://development.saas.neotas.com/blackbox/api/v1/internal-service/scratch-case";
    return await ax.post(url, payload, {headers:{"Authorization":`Bearer ${saasToken}`}})
}

export async function createNewNeotascase(payload) {
    const url = "/api/v1/user/saas-token"
    return await blackboxAxios.post(url, payload)
}

export async function fetchRefreshToken(token) {
    const base = getBaseUrlDelviumBackend()
    const url = `${base}/user-auth/token`;
    // const url = `/api/v1/user-auth/token`;
    try {
        let response = await rawAxios.post(url,{token});
        return response.data.access_token;
    } catch (error) {
        console.error(error);
        throw new Error("Refresh token failed from server!");
    }
}

export async function fetchRefreshSaasToken(token) {
    const base = getBaseUrlDelviumBackend()
    const url = `${base}/user/saas-token`;
    // const url = `/api/v1/user-auth/token`;
    try {
        let response = await rawAxios.post(url,{token});
        return response.data.access_token;
    } catch (error) {
        console.error(error);
        throw new Error("Refresh token failed from server!");
    }
}

// Search API By Company Name and Domain 

export async function getSearchedRecords(payload) {
    const url = "/api/v1/company/search";
    return await osintAxios.post(url,payload, {headers: {"x-tool-name": "company-name","x-case-id": payload.neo_case_id,"x-status":"RUNNING"}})
}

export async function getSearchedDomainRecords(payload) {
    const url = "/api/v1/domain/registration/domain-name";
    return await osintAxios.post(url,payload, {headers: {"x-tool-name": "company-name","x-case-id": payload.neo_case_id,"x-status":"RUNNING"}})
}


//Inteligence API and Status Api hit on every 10 sec until get checkstatus true

export async function getRunInteligenceDetails(payload) {
    const url = "/api/v1/social-media-automation/case-run-intelligence";
    return await blackboxAxios.post(url, payload)
}
export async function getInteligenceStatus(payload) {
    const url = `/api/v1/social-media-automation/check-status/${payload.case_id}`;
    return await blackboxAxios.get(url)
}

// Entity Data Api after Inteligence API check status true
export async function getEntityIdentifiers(payload) {
    const url = `/api/v1/social-media-automation/delvium-entities-data`;
    return await blackboxAxios.post(url,payload);
}

// Jurisdiction List
export async function getjurisdictionList() {
    const url = "/api/v1/company/search/jurisdiction?cache_bypass=false";
    return await osintAxios.get(url);
}

//internet search API for internet details
export async function getinternetDataDetails(payload){
    const url="api/v1/search-engine/multiqueryresults"
    return await blackboxAxios.post(url,payload,{headers: {"x-tool-name": "company-name","x-case-id": payload.case_id,"x-status":"RUNNING"}})
}

//master API for risk categories
export async function getriskcategories(payload){
    const url="api/v1/risk-categories"
    return await blackboxAxios.get(url,{headers: {"x-tool-name": "company-name","x-case-id": payload.case_id,"x-status":"RUNNING"}})
}

//master API for relation attributes
export async function getrelationAttributes(){
    const url="api/v1/entities-relations-attributes"
    return await blackboxAxios.get(url)
}

//Update API for entity data update 
export async function updateEntityDetails(payload,doc_id){
    const url=`api/v1/social-media-automation/delvium-entities-data/${doc_id}`
    return await blackboxAxios.put(url,payload,{headers: {"x-case-id": payload.case_id,"x-status":"RUNNING"}})
}

//Upload Image on S3 bucket
export async function uploadAttachment(payload,case_id){
    console.log("case_id",case_id)
    const url=`api/v1/uploads/reports/images`
    return await blackboxAxios.post(url,payload,{headers: {"x-case-id": case_id,"x-status":"RUNNING"}})
}

//Details APIs services
export async function getAdverseDetails(payload) {
    const url = "/api/v1/adverse-media/comply-advantage/details"
    return await osintAxios.post(url,payload,{headers: {"x-tool-name": "adverse-media-tool","x-case-id": payload.case_id,"x-status":"RUNNING"}});
}

export async function getInternetDetails(payload) {
    const url = `/api/v1/google-search-data/search-data/${payload.doc_id}`
    return await blackboxAxios.get(url,{headers: {"x-case-id": payload.case_id,"x-status":"RUNNING"}});
}

export async function getOffshoreDetails(payload) {
    const url = "/api/v1/adverse-media/offshoreleaks/details"
    return await osintAxios.post(url,payload,{headers: {"x-tool-name": "adverse-media-tool","x-case-id": payload.case_id,"x-status":"RUNNING"}});
}

export async function getNewsDetails(payload) {
    const url = "/api/v1/news/news/details"
    return await osintAxios.post(url,payload,{headers: {"x-tool-name": "adverse-media-tool","x-case-id": payload.case_id,"x-status":"RUNNING"}});
}